define(['app', '$window', 'componentHelper', 'accessibilityFocusHelper'], (app, $window, componentHelper, accessibilityFocusHelper) => {
  const foundationFinderMultiBrandQuiz = () => {
    const component = {};
    const trackingEventCategory = 'Foundation Finder';

    component.config = {
      selectors: {
        element: '[data-component=foundationFinderMultiBrandQuiz]',
        productSelector: '[data-component=foundationFinderProductMatcherQuestion]',
        resultsPath: '.foundationFinderMultiBrand_quiz'
      },
      classes: {
        hide: 'hide',
      },
      attributes: {
        dataHref: 'data-href'
      },
      channels: {
        quizAnswers: 'quiz/quizAnswers',
        quizSaveCurrentAnswer: 'quiz/saveCurrentAnswer',
        sendSelectedAnswers: 'foundationFinderMultiBrandUploader/saveSelectedAnswers'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.productSelector = component.element.querySelector(component.config.selectors.productSelector);
      component.resultsPath = document.querySelector(component.config.selectors.resultsPath);
      component.subscribe();
      app.publish('tracking/record', trackingEventCategory, 'Quiz Start');
    };

    component.subscribe = () => {
      app.subscribe(component.config.channels.quizSaveCurrentAnswer, component.publishTrackingQuizAnswers);
      app.subscribe(component.config.channels.quizAnswers, component.quizSubmitted);
    };

    component.publishTrackingQuizAnswers = (quizCurrentTitle, selectedAnswers) => {
      app.publish('tracking/record', component.trackingEventCategoryWithCardFlow, quizCurrentTitle, selectedAnswers)
    }

    component.quizSubmitted = (selectedAnswers) => {
      component.selectedAnswers = {};
      Array.from(selectedAnswers.entries()).forEach(([name, value])=> {
        component.selectedAnswers[name] = value
      });

      component.postQuizAnswers(selectedAnswers)
    };

    component.postQuizAnswers = (selectedAnswers) => {
      if (selectedAnswers) {
        component.selectedAnswers = [];
        var sourceProduct = null;
        Array.from(selectedAnswers.entries()).forEach(([name, values])=> {
          for (let value of values) {
            if (name == 'sourceProduct') {
              sourceProduct = values;
            } else {
              component.selectedAnswers.push(`${name}:${value}`);
            }
          }
        });
      }

      component.facetFilters = component.selectedAnswers.join("|");
      $window.location = `${component.resultsPath.getAttribute(component.config.attributes.dataHref)}?sourceProduct=${sourceProduct}&facetFilters=${component.facetFilters}`
    };

    return component;
  };

  return foundationFinderMultiBrandQuiz;
});
